export const ApplicationSelectInput = ({
  value,
  text,
  className = "",
  optionList = [],
  onChange,
}) => {
  function onSelectInput(e) {
    onChange(e.target.value);
  }
  return (
    <div className={`applicationInput ${className}`} onChange={onSelectInput}>
      <div> {text}</div>
      <select value={value} key={text} required>
        <option value="" disabled selected>
          Выберите
        </option>
        {optionList.map((item) => {
          return (
            <option key={item} value={item}>
              {item}
            </option>
          );
        })}
      </select>
    </div>
  );
};
