export const ApplicationCheckboxInput = ({
  value,
  text,
  className = "",
  onChange,
}) => {
  function onCheckInput(e) {
    onChange(e.target.checked);
  }

  return (
    <div className={`applicationInput ${className}`}>
      <div> {text}</div>
      <input checked={value} onChange={onCheckInput} type="checkbox" />
    </div>
  );
};
