import { useState } from "react";
import logo from "../../Assets/Images/logo.PNG";

const BurgerMenu = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="burgerMenu">
      <div
        className="burgerButtonBox"
        onClick={() => {
          setOpen(true);
        }}
      >
        <div className="burgerButton">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      {open && (
        <div className="popUp">
          <div className="burgerContainer">
            <div className="burgerBox">
              <div className="closeBurgerBox">
                <div
                  className="closeBurgerButton"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div className="burgerContent">
                <a href="https://заодно.team">
                  <img alt="" src={logo} className="burgerLogo"></img>
                </a>
                <a href="https://xn--80aih0adc.team/#direction">Направления</a>
                <a href="https://xn--80aih0adc.team/#partners">Наши партнеры</a>
                <a href="https://xn--80aih0adc.team/#about">О нас</a>
                <a href="https://xn--80aih0adc.team/#waiting">Кого мы ждем</a>
                <a href="https://xn--80aih0adc.team/#sponsor">
                  Стать спонсором
                </a>
                <a href="https://xn--80aih0adc.team/#contacts">Конакты</a>
                <a href="https://xn--80aih0adc.team/#popup:choice">
                  Регистрация{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BurgerMenu;
