import axios from "axios";

export class RegistrationApi {
  static status;
  static id;
  static data;

  static async registrate({ email, password }) {
    await axios
      .post("https://forone.2ng.ru/auth/register", {
        email: email,
        password: password,
      })
      .then((response) => {
        this.id = response.data.session_id;
        this.status = response.status;
        this.data = response.data;
      })
      .catch((error) => {
        this.status = error.response.status;
        this.data = error.response.data.detail;
      });

    return { status: this.status, id: this.id, data: this.data };
  }
}
