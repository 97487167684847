import axios from "axios";

export class ApplicationApi {
  static status;
  static id;

  static async sendTeam(data) {
    await axios
      .post(
        "https://forone.2ng.ru/teams/teams",
        {
          name: data.name ? data.name : "",
          count: 0,
          disability: data.disability,
          video: data.video,
          points: 0,
          timer: "string",
        },
        {
          params: {
            session_id: localStorage.getItem("sessionId"),
          },
        }
      )
      .then((response) => {
        this.status = response.status;
        this.id = response.data.id;
      })
      .catch((error) => {
        this.status = error.response.status;
      });

    if (data.photo) {
      const formData = new FormData();
      formData.append("photo", data.photo);

      await axios.post(
        `https://forone.2ng.ru/teams/teams/${this.id}/photo`,

        formData,
        {
          params: {
            "session-id": localStorage.getItem("sessionId"),
            team_id: this.id,
          },
          headers: {
            "session-id": localStorage.getItem("sessionId"),
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
  }

  static async sendTeamMember(member) {
    const formData = new FormData();

    formData.append("last_name", member.secondName);
    formData.append("first_name", member.firstName);
    formData.append("middle_name", member.thirdName);
    formData.append("gender", member.gender);
    formData.append("birthdate", member.date);
    formData.append("school", member.studyPlace);
    formData.append("home_address", member.homeAddress);
    formData.append("phone", member.phoneNumber);
    formData.append("email", member.email);
    if (member.photo) {
      formData.append("photo", member.photo);
    }
    formData.append(
      "disability",
      member.disability ? member.disability : false
    );
    formData.append("disability_group", member.group);

    await axios
      .post(
        `https://forone.2ng.ru/teams/teams/${this.id}/members`,
        formData,

        {
          params: {
            "session-id": localStorage.getItem("sessionId"),
            team_id: this.id,
          },
          headers: {
            "session-id": localStorage.getItem("sessionId"),
          },
        }
      )
      .then((response) => {
        this.status = response.status;
      })
      .catch((error) => {
        this.status = error.response.status;
      });
    return { status: this.status };
  }
}
