import logo from "../../Assets/Images/logo.PNG";
import RGULogo from "../../Assets/Images/ргу.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footerContent">
        <div className="footerBlock">
          <a href="https://xn--80aih0adc.team/">
            <img alt="" src={logo} className="footerLogoImage"></img>
          </a>{" "}
          <a href="https://rgust.ru/">
            <img alt="" src={RGULogo} className="footerRGUImage"></img>
          </a>
        </div>
        <div className="footerBlock">
          <div>2024 «ЗАОДНО» Все права защищены</div>
          <div>
            <div>107150, г. Москва, ул. Лосиноостровская, 49</div>
            <div>Приёмная ректора: +7 (499) 160-92-00</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
