import InputMask from "react-input-mask";

export const ApplicationPhoneInput = ({
  value,
  text,
  placeholder,
  className = "",
  type = "text",
  onChange,
  disabled = false,
}) => {
  function onTextInput(e) {
    onChange(e.target.value);
  }

  return (
    <div className={`applicationInput ${className}`}>
      <div> {text}</div>
      <InputMask
        value={value}
        disabled={disabled}
        mask={"+7(999)999-99-99"}
        key={text}
        onChange={onTextInput}
        type={type}
        placeholder={` ${placeholder}`}
        required
      />
    </div>
  );
};
