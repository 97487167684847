import { useEffect, useRef, useState } from "react";
import { ApplicationTextInput } from "../../UI/Application/ApplicationInputs/ApplicationTextInput";
import { Participant } from "./Participant";
import { AddParticipantButton } from "../../UI/Application/ApplicationButtons/AddParticioantButton";
import { DeleteParticipantButton } from "../../UI/Application/ApplicationButtons/DeleteParticipantButton";
import { ApplicationFileInput } from "../../UI/Application/ApplicationInputs/ApplicationFileInput";
import soloPersonalDoc from "../../../Assets/Docs/Согласие_на_обработку_персональных_данных.pdf";

export const Application = ({
  teamCallback,
  listCallback,
  openCallback,
  soloCallback,
  docCallback,
}) => {
  const [participantList, setParticipantList] = useState([{ number: 1 }]);
  const [previosNumber, setPreviosNumber] = useState(0);
  const [participantNumber, setParticipantNumber] = useState(1);
  const [solo, setSolo] = useState(true);
  const [teamInfo, setTeamInfo] = useState({
    name: "",
    url: "",
    video: "",
    photo: "",
    disability: false,
  });
  const listRef = useRef();

  function incrementParticipentNumber() {
    if (participantNumber < 15) {
      setPreviosNumber((state) => state + 1);
      setParticipantNumber((state) => state + 1);

      setParticipantList((participantList) => [
        ...participantList,
        { number: participantNumber },
      ]);
    } else {
      alert("Может быть до 15 участников");
    }
  }

  function decrementParticipentNumber() {
    if (participantList.length > 2) {
      setPreviosNumber((state) => state - 1);
      setParticipantNumber((state) => state - 1);
      let list = [...participantList];
      list.splice(-1, 1);
      setParticipantList(list);
    }
  }

  // useEffect(() => {
  //   if (participantNumber > previosNumber && participantNumber < 16) {

  //   } else if (participantNumber < previosNumber) {

  //   }
  // }, [participantNumber, previosNumber]);

  useEffect(() => {
    if (participantNumber > previosNumber && participantNumber < 16) {
      listRef.current.scrollTo(0, listRef.current.scrollHeight);
    }

    listCallback(participantList);
  }, [participantList.length]);

  useEffect(() => {
    listCallback(participantList);
  }, [participantList]);

  function handleInput(e, participantNumber, type) {
    const myNextList = [...participantList];
    const participant = myNextList.find(
      (item) => item.number === participantNumber
    );

    participant[type] = e;

    if ((typeof e === "string" && e.trim() === "") || e === false) {
      delete participant[type];
    }
    setParticipantList(myNextList);
  }

  function handleTeam(e, type) {
    const info = teamInfo;

    if (type === "photo") {
      info[type] = e.files[0];
    } else {
      info[type] = e;
    }

    setTeamInfo(info);
  }

  function onSoloClick() {
    setSolo(true);
    setParticipantList([
      {
        number: 1,
        secondName: "",
        firstName: "",
        thirdName: "",
        date: "",
        email: "",
        gender: "",
        group: "",
        homeAddress: "",
        phoneNumber: "",
        studyPlace: "",
        workPlace: "",
        faculty: "",
        jobTitle: "",
      },
    ]);
  }

  function onTeamClick() {
    setSolo(false);
    setParticipantList([
      {
        number: 1,
        secondName: "",
        firstName: "",
        thirdName: "",
        date: "",
        email: "",
        gender: "",
        group: "",
        homeAddress: "",
        phoneNumber: "",
        studyPlace: "",
        workPlace: "",
        faculty: "",
        jobTitle: "",
      },
    ]);
    setPreviosNumber(1);
    setParticipantNumber(2);
  }

  useEffect(() => {
    soloCallback(solo);
  }, [solo]);

  useEffect(() => {
    teamCallback(teamInfo);
  }, [teamInfo]);

  return (
    <div className="application">
      <div className="topApplication">
        <div className="selectTeamType">
          {" "}
          <div
            className={`soloButton ${solo ? "selectedTeamType" : ""}`}
            onClick={onSoloClick}
          >
            Сольный исполнитель
          </div>
          <div
            className={`teamButton ${solo ? "" : "selectedTeamType"}`}
            onClick={onTeamClick}
          >
            Коллектив/дуэт
          </div>
        </div>
        {!solo && (
          <ApplicationTextInput
            className="topApplicationInput"
            text="Название коллектива/дуэта"
            placeholder="Введите название"
            onChange={(e) => handleTeam(e, "name")}
          />
        )}

        <ApplicationTextInput
          className="topApplicationInput"
          text={"Ссылка на аккаунт в социальных сетях"}
          placeholder={"Вставьте ссылку"}
          onChange={(e) => handleTeam(e, "url")}
        />
        <ApplicationTextInput
          className="topApplicationInput"
          text={"Cсылка на видео"}
          placeholder={"Вставьте ссылку"}
          onChange={(e) => handleTeam(e, "video")}
        />
        <div
          className="videoInfo"
          onClick={() => {
            openCallback(true);
          }}
        >
          Требования к видео
        </div>
        <ApplicationFileInput
          className="topApplicationInput"
          text={solo ? "Фото участника" : "Фото команды"}
          onChange={(e) => handleTeam(e, "photo")}
        />
        {!solo && (
          <div className="memo">
            *Памятка: в случае, если у дуэта нет названия - в данной строке
            указываются фамилии участников дуэта через запятую <br></br>
            <br></br>
            Внимание: дуэт или музыкальный коллектив обязательно должны быть
            инклюзивными - с участием исполнителей с ОВЗ и инвалидностью
          </div>
        )}
      </div>

      <div className="participantList" ref={listRef}>
        {" "}
        {participantList.map((participant) => {
          return (
            <Participant
              participant={participant}
              key={participant.number}
              secondNameInput={(e) =>
                handleInput(e, participant.number, "secondName")
              }
              firstNameInput={(e) =>
                handleInput(e, participant.number, "firstName")
              }
              thirdNameInput={(e) =>
                handleInput(e, participant.number, "thirdName")
              }
              dateInput={(e) => handleInput(e, participant.number, "date")}
              studyPlaceInput={(e) =>
                handleInput(e, participant.number, "studyPlace")
              }
              workPlaceInput={(e) =>
                handleInput(e, participant.number, "workPlace")
              }
              facultyInput={(e) =>
                handleInput(e, participant.number, "faculty")
              }
              jobTitleInput={(e) =>
                handleInput(e, participant.number, "jobTitle")
              }
              homeInput={(e) =>
                handleInput(e, participant.number, "homeAddress")
              }
              phoneNumberInput={(e) =>
                handleInput(e, participant.number, "phoneNumber")
              }
              emailInput={(e) => handleInput(e, participant.number, "email")}
              groupInput={(e) => handleInput(e, participant.number, "group")}
              disabilityInput={(e) =>
                handleInput(e, participant.number, "disability")
              }
              genderInput={(e) => handleInput(e, participant.number, "gender")}
              photoInput={(e) => handleInput(e, participant.number, "photo")}
              medCertificate={(e) =>
                handleInput(e, participant.number, "medCertificate")
              }
            />
          );
        })}
      </div>
      {!solo && (
        <div className="applicationListSetup">
          <AddParticipantButton
            text={"Добавить участника"}
            callback={incrementParticipentNumber}
          />
          <DeleteParticipantButton
            text={"Удалить участника"}
            callback={decrementParticipentNumber}
          />
        </div>
      )}
      {solo && (
        <div className="personalBox">
          <input
            onChange={(e) => {
              docCallback(e.target.checked);
            }}
            type="checkbox"
          ></input>
          <a href={soloPersonalDoc}>
            Согласен на обработку персональных данных *
          </a>
        </div>
      )}
    </div>
  );
};
