import { DesignedButton } from "../../UI/DesignedButton";
import emptyMember from "../../../Assets/Images/empty_member.png";

const MemberPopUp = ({ memberInfo, close }) => {
  function onClosePop() {
    close(false);
  }

  return (
    <div className="popUp">
      <div className="videoPopUp">
        <div className="memberPopUpContent">
          <div className="memberImageBox">
            {memberInfo.photo ? (
              <img
                alt="Фото участника"
                src={`https://forone.2ng.ru/${memberInfo.photo}`}
              />
            ) : (
              <img alt="Фото участника" src={emptyMember} />
            )}
          </div>

          <div className="memberPopBlock">
            <div>{memberInfo.last_name}</div>
            <div>{memberInfo.first_name}</div>
            <div>{memberInfo.middle_name}</div>
          </div>
          <div className="memberPopBlock">
            <div>Номер телефона:</div>
            <div>{memberInfo.phone}</div>
          </div>
          <div className="memberPopBlock">
            <div>Электронная почта:</div>
            <div>{memberInfo.email}</div>
          </div>
          <div className="memberPopBlock">
            <div>Адрес проживания:</div>
            <div>{memberInfo.home_address}</div>
          </div>
          <div className="memberPopBlock">
            <div>Место обучения:</div>
            <div>{memberInfo.school}</div>
          </div>
        </div>
        <div className="closePopUp">
          <DesignedButton
            text={"ЗАКРЫТЬ"}
            callback={() => onClosePop()}
          ></DesignedButton>
        </div>
      </div>
    </div>
  );
};

export default MemberPopUp;
