import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RegistrationApi } from "../Api/RegistrationApi";
import { DesignedButton } from "../Components/UI/DesignedButton";
import { DesignedInput } from "../Components/UI/DesignedInput";
import { DesignedPassword } from "../Components/UI/DesignedPassword";

export const RegistrationPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVer, setPasswordVer] = useState("");

  function onEmailChange(e) {
    setEmail(e);
  }

  function onPasswordChange(e) {
    setPassword(e);
  }

  function onPasswordVerChange(e) {
    setPasswordVer(e);
  }

  async function fetch() {
    if (password === passwordVer) {
      const loginFetch = await RegistrationApi.registrate({ email, password });

      if (loginFetch.status === 200) {
        localStorage.setItem("sessionId", loginFetch.id);
        alert(
          "На вашу электронную почту, указанную при регистрации, отправлено тестовое письмо для подтверждения электронного адреса. Пожалуйста, зайдите в Ваш почтовый ящик, откройте письмо и пройдите по содержащейся в нем ссылке"
        );
        navigate("/");
      } else if (loginFetch.status !== 200) {
        if (
          loginFetch.data ===
          "Password must be at least 8 characters long, include both uppercase and lowercase letters, and contain numbers."
        ) {
          alert(
            "Пароль должен содержать не менее 8 символов, как заглавные, так и строчные буквы, а также цифры."
          );
          return;
        } else if (loginFetch.data === "Email is already registered") {
          alert("Почта уже зарегистрирована");
        } else {
          alert("Возникла ошибка");
          return;
        }
      }
    } else {
      alert("Пароли не совпадают");
      return;
    }
  }

  function onRegistration(e) {
    e.preventDefault();
    fetch();
  }

  return (
    <div className="registrationPage">
      <form className="registrationForm">
        <DesignedInput
          type={"email"}
          placeholder={"Введите почту"}
          onChange={onEmailChange}
          required={true}
        />
        <DesignedPassword
          type={"password"}
          placeholder={"Придумайте пароль"}
          onChange={onPasswordChange}
          required={true}
        />
        <DesignedPassword
          type={"password"}
          placeholder={"Повторите пароль"}
          onChange={onPasswordVerChange}
          required={true}
        />
        <DesignedButton text={"Зарегистрироваться"} callback={onRegistration} />
        <div className="goEnter">
          <div>Уже зарегистрированы?</div>
          <div className="switchSign" onClick={() => navigate("/")}>
            Войти
          </div>
        </div>
      </form>
    </div>
  );
};
