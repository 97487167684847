import axios from "axios";

export class RecoverApi {
  static status;
  static id;
  static data;

  static async recover({ email }) {
    await axios
      .post(
        `https://forone.2ng.ru/auth/recover-password`,
        {
          email: email,
        },
        { params: { email: email } }
      )
      .then((response) => {
        this.id = response.data.session_id;
        this.status = response.status;
      })
      .catch((error) => {
        this.status = error.response.status;
        this.data = error.response.data.detail;
      });

    return { status: this.status, id: this.id, data: this.data };
  }
}
