import { useState } from "react";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import Icon from "react-icons-kit";

export const DesignedPassword = ({ placeholder, type, required, onChange }) => {
  const [show, setShow] = useState(false);

  function showPassword() {
    show ? setShow(false) : setShow(true);
  }

  function onTextInput(e) {
    onChange(e.target.value);
  }

  return (
    <div className="designedPassword">
      <input
        type={show ? "text" : "password"}
        className="passwordInput"
        placeholder={placeholder}
        onChange={onTextInput}
        required={required}
      />
      <span className="eye" onClick={showPassword}>
        <Icon icon={show ? eye : eyeOff} size={30}></Icon>
      </span>
    </div>
  );
};
