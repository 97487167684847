import axios from "axios";

export class TeamAccountApi {
  static id;

  static async getTeam() {
    let list = [];
    await axios
      .get("https://forone.2ng.ru/teams/teams", {
        params: {
          session_id: localStorage.getItem("sessionId"),
        },
      })
      .then((response) => {
        list = response.data;

        if (list.length > 0) {
          this.id = list[list.length - 1].id;
        }
      });

    return list;
  }

  static async getMemberList() {
    let list = [];
    await axios
      .get(`https://forone.2ng.ru/teams/teams/${this.id}/members`, {
        params: {
          session_id: localStorage.getItem("sessionId"),
        },
        headers: {
          "session-id": localStorage.getItem("sessionId"),
        },
      })
      .then((response) => {
        list = response.data;
      });

    return list;
  }
}
