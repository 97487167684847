import { DesignedButton } from "../../UI/DesignedButton";

const VideoPopUp = ({ close }) => {
  function onClosePop() {
    close(false);
  }

  return (
    <div className="popUp">
      <div className="videoPopUp">
        <div className="videoPopUpContent">
          Требования к видеоролику: <br></br>- Каждый Участник (индивидуальный
          исполнитель, дуэт или коллектив) представляет видеоматериал конкурсной
          программы из одного – трёх произведений, раскрывающий возможности и
          умения Участника c общим хронометражем 8-12 минут. <br></br>-
          Видеоматериал должен быть снят на статичную камеру (без элементов
          монтажа, склейки кадров, наложения аудиодорожек, без посторонних
          шумов, с хорошим освещением). <br></br>- Параметры видеофайла: формат
          AVI, MPG4, MOV, разрешение видео не более 1080×1920 (1080 p, Full HD),
          но не менее 720×1280 (720 p, HD). <br></br>
          <br></br>Видеоролик должен содержать: <br></br>- краткий рассказ о
          себе (ФИО, город, возраст, хобби и увлечения, название и авторство
          музыкального произведения); <br></br>- исполнение конкурсной программы
          из одного-трёх, по возможности разнохарактерных произведений, c общим
          хронометражем 8-12 минут; <br></br>- конкурсная работа должна быть
          выполнена самостоятельно, использование уже имеющегося в интернете
          материала запрещается; <br></br>- ориентация видеоролика –
          горизонтальная, в статичном режиме съемки; <br></br>- Участник
          Конкурса на протяжении всего видеоролика должен находиться в кадре;{" "}
          <br></br>- на Конкурс не принимаются видеоролики рекламного характера,
          оскорбляющие достоинства и чувства других людей; <br></br>- содержание
          видеороликов не должно противоречить законодательству Российской
          Федерации; <br></br>- видео должно быть размещено в открытом доступе и
          доступно для скачивания. <br></br>- материалы, к которым доступ будет
          закрыт, конкурсной комиссией не рассматриваются; <br></br>- не
          допускается репертуар, который носит антагонистический и
          националистический характер, сцены насилия, а также противоречащий
          этическим и моральным нормам.
        </div>
        <div className="closePopUp">
          <DesignedButton
            text={"ЗАКРЫТЬ"}
            callback={() => onClosePop()}
          ></DesignedButton>
        </div>
      </div>
    </div>
  );
};

export default VideoPopUp;
