import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ApplicationPage } from "./Pages/ApplicationPage";
import { LoginPage } from "./Pages/LoginPage";
import { RegistrationPage } from "./Pages/RegistrationPage";
import { AdminAccountPage } from "./Pages/AdminAccountPage";
import { VideoURLPage } from "./Pages/VideoURLPage";
import { TeamAccountPage } from "./Pages/TeamAccountPage";
import Header from "./Components/Semantics/Header";
import Footer from "./Components/Semantics/Footer";
import BurgerMenu from "./Components/Semantics/BurgerMenu";
import { RecoverPage } from "./Pages/RecoverPage";

function App() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="App">
      {width > 1200 ? <Header /> : <BurgerMenu />}
      <Routes>
        <Route path="/team" element={<TeamAccountPage />} />
        <Route path="/registration" element={<RegistrationPage />} />
        <Route path="/recover" element={<RecoverPage />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/application" element={<ApplicationPage />} />
        {/* <Route path="/" element={<AdminAccountPage />} /> */}
        {/* <Route path="/" element={<VideoURLPage />} /> */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
